import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';

export class IconButton extends PureComponent {
    render() {
        const { text, icon, ...other } = this.props;

        return (
            <Button {...other}><FontAwesomeIcon icon={icon} className="mr-1" />{text}</Button>
        );
    }
}

IconButton.propTypes = {
    text: PropTypes.string,
    icon: PropTypes.arrayOf(PropTypes.string).isRequired,
};
