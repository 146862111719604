import React, { Component } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { faPlayCircle } from '@fortawesome/free-regular-svg-icons';
import { Container, Row, Col, Navbar, NavbarBrand } from 'reactstrap';
import { MigrationsPanel } from './components/MigrationsPanel';

// Add here all FontAwesome icons
// that will be loaded in more than one component
library.add(faPlayCircle, faSync);

export default class App extends Component {
    render() {
        return (
            <React.Fragment>
                <Navbar color="dark" expand="md" dark>
                    <NavbarBrand>
                        <img src="/images/logo-white.svg" alt="Helix Migrations Server" height="30" className="d-inline-block align-top" />
                    </NavbarBrand>
                </Navbar>
                <main>
                    <Container className="py-3" fluid>
                        <Row>
                            <Col>
                                <MigrationsPanel />
                            </Col>
                        </Row>
                    </Container>
                </main>
            </React.Fragment>
        );
    };
}
