import React, { Component } from 'react';
import PropTypes from 'prop-types';

export class Select extends Component {
    handleChange = (event) => {
        this.props.onChange(event.target.value);
    }

    render() {
        const { options, onChange, ...other } = this.props;

        return (
            <select onChange={this.handleChange} {...other}>
                {options.map((option, i) =>
                    <option key={i} value={option.value}>{option.label}</option>
                )}
            </select>
        );
    }
}

Select.propTypes = {
    className: PropTypes.string,
    value: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
    })).isRequired,
    onChange: PropTypes.func.isRequired,
};

Select.defaultProps = {
    className: "form-control",
};
