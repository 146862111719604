import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export class Spinner extends PureComponent {
    render() {
        const { iconRight, text, icon, ...other } = this.props

        if (iconRight) {
            return (
                <span {...other}>{text}<FontAwesomeIcon icon={icon} spin className="ml-1" /></span>
            );
        } else {
            return (
                <span {...other}><FontAwesomeIcon icon={icon} spin className="mr-1" />{text}</span>
            );
        }
    };
}

Spinner.propTypes = {
    iconRight: PropTypes.bool.isRequired,
    text: PropTypes.string,
    icon: PropTypes.arrayOf(PropTypes.string).isRequired,
};

Spinner.defaultProps = {
    iconRight: true
};
