export const postData = (url, data = {}, other = {}) =>
    fetch(url, Object.assign({
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    }, other))
    .then(response => response.json());

export const httpGetJson = function (params) {
    const { url, headers, ...other } = params;

    let init = Object.assign(other, {
        method: "GET",
        dataType: "JSON",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
        }
    });

    return fetch(url, init)
        .then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        });
};
