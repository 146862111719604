import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export class Textarea extends PureComponent {
    render() {
        return (
            <textarea {...this.props} />
        );
    };
}

Textarea.propTypes = {
    className: PropTypes.string,
    value: PropTypes.string,
};

Textarea.defaultProps = {
    className: "form-control",
};
