import React, { Component } from 'react';
import { Navbar, Card, CardHeader, CardBody, CardTitle, Form, FormGroup, Label } from 'reactstrap';
import { IconButton } from './IconButton';
import { Textarea } from './Textarea';
import { Select } from './Select';
import { Spinner } from './Spinner';
import { postData } from '../helpers';

const LoadingMsg = "Executing latest migrations...";
const ApiUrl = "/api/migrations";


// get psk from query
var urlParams = new URLSearchParams(window.location.search);
const key = (urlParams.get('key'));

const migrationModeOptions = [
    { label: "Full Update", value: "migrate", },
    { label: "Profiles Only", value: "RunProfiles" },
    { label: "Pre-Deployment Only", value: "PreDeployment" },
];

export class MigrationsPanel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isRunning: false,
            migrationMode: migrationModeOptions[0].value,
            migrationResult: "",
        };
    };

    onModeSelected = (mode) => {
        this.setState({
            migrationMode: mode
        });
    };

    runPreviewTask = () => {
        this.runTask(true);
    };

    runMigrateTask = () => {
        this.runTask(false);
    };

    migrationInitiate() {
        this.setState({
            isRunning: true,
            migrationResult: LoadingMsg
        });
    };

    migrationDone(result) {
        this.setState({
            isRunning: false,
            migrationResult: result.data
        });
    };

    runTask = (previewOnly) => {
        if (this.state.isRunning) {
            return;
        }

        this.migrationInitiate();

        const tags = [this.state.migrationMode];
        postData(ApiUrl, { previewOnly, task: "migrate", key, tags })
            .then((result) => {
                this.migrationDone(result);
            })
            .catch((error) => {
                this.migrationDone({
                    successful: false,
                    data: error.message || error.stack || "Unknown error occurred! WTF!!??"
                });
            });
    };

    render() {
        return (
            <Card>
                <CardHeader className="p-0">
                    <Navbar color="dark" className="text-white rounded-top" expand="md" dark>
                        <Form tag="div" inline>
                            <FormGroup className="mr-4 mb-0">
                                <Label className="mr-2 d-none d-sm-block">Mode:</Label>
                                <Select value={this.state.migrationMode} options={migrationModeOptions} onChange={this.onModeSelected} />
                            </FormGroup>
                            <FormGroup className="mr-4 mb-0">
                                <IconButton color="primary" text="Execute Task" icon={["far", "play-circle"]} onClick={this.runMigrateTask} disabled={this.state.isRunning} />
                            </FormGroup>
                            <FormGroup className="mr-4 mb-0">
                                <IconButton color="light" text="Preview Task" icon={["far", "play-circle"]} onClick={this.runPreviewTask} disabled={this.state.isRunning} />
                            </FormGroup>
                        </Form>
                    </Navbar>
                </CardHeader>
                <CardBody>
                    <CardTitle className="d-inline-block">Output Log</CardTitle>
                    {this.state.isRunning && <Spinner className="ml-2" icon={["fas", "sync"]} />}
                    <Textarea style={{ "minHeight": "500px" }} value={this.state.migrationResult} readOnly />
                </CardBody>
            </Card>
        );
    };
}
